<template>
  <div class="flex flex-col justify-center gap-y-5 max-w-6xl">
    <h1 class="text-white text-6xl font-semibold self-center">SHIPPING INFO</h1>
    <span class="text-white self-center">EACH THEM HAT IS UNIQUE, SHIPPING TIMES COULD EXCEED 90 DAYS</span>
    <div class="flex justify-center">
      <el-form ref="form_responses" :rules="rules" :model="form_responses">
        <!-- Name -->
        <el-form-item prop="name">
          <el-input v-model="form_responses.name" placeholder="NAME (PSEUDONAMES ARE FINE)" ></el-input>
        </el-form-item>
        <!-- Address 1 -->
        <el-form-item prop="address1">
          <el-input v-model="form_responses.address1" placeholder="ADDRESS 1" ></el-input>  
        </el-form-item>
        <!-- Address 2 -->
        <el-form-item prop="address2">
          <el-input v-model="form_responses.address2" placeholder="ADDRESS 2" ></el-input>  
        </el-form-item>
        <!-- Country -->
        <el-form-item prop="country">
          <el-input v-model="form_responses.country" placeholder="COUNTRY"></el-input>  
        </el-form-item>
        <!-- City -->
        <el-form-item prop="city">
          <el-input v-model="form_responses.city" placeholder="CITY/TOWN" ></el-input>  
        </el-form-item>
        <!-- State -->
        <div class="flex justify-between">
          <el-col :span="11">
            <el-form-item prop="state">
              <el-input v-model="form_responses.state" placeholder="STATE/PROV" ></el-input>  
            </el-form-item>
          </el-col>
          <!-- Zip -->
          <el-col :span="11">
            <el-form-item prop="zip">
              <el-input v-model="form_responses.zip" placeholder="POSTAL CODE" ></el-input>  
            </el-form-item>
          </el-col>
        </div>
      </el-form>
    </div>
    <button class="max-w-sm py-2 px-16 bg-neon_green m-auto text-black font-semibold" @click="submit()">NEXT</button>
    <!-- <span class="text-white self-center"></span> -->
    <span class="text-lg text-red-500 self-center">{{error}}</span>
  </div>
</template>

<script>
export default {
  name: 'shipping_info',
  props: {
    msg: String
  },
  data(){
    return{
      form_responses:{
        name: '',
        address1: '',
        address2: '',
        country: '',
        city: '',
        state: '',
        zip: ''
      },
      rules:{
        name: this._rule_constructor('Name', true),
        address1: this._rule_constructor('Address 1',true),
        address2: this._rule_constructor('Address 2', false),
        country: this._rule_constructor('Country',true),
        city: this._rule_constructor('City or Town', true),
        state: this._rule_constructor('State or Province', true),
        zip: this._rule_constructor('Postal Code', true)
      },
      error: null
    }
  },
  methods:{
    _rule_constructor(field_name, required){
      return [{ required: required, message: field_name + ' is required', trigger: 'blur'}]
    },
    submit(){
      this.$refs['form_responses'].validate(valid=>{
        if(valid){
          this.$emit('shipping_info_entered',this.form_responses)
        }else{
          this.error="You must fill all required fields before continuing"
        }
      })
    }
  }
}
</script>

