<template>
    <!-- First page for connecting wallet -->
    <div>
      <div class="flex flex-col justify-center text-center space-y-5">
        <h1 class="text-7xl font-semibold">{{hero_settings.main}}</h1>
        <span class="text-lg">{{hero_settings.sub}}</span>
        <span class="text-lg">CONFIRMED TRANSACTION(S):</span>
        <div v-if="this.txn_exists">
            <a :href="'https://etherscan.io/tx/'+confirmed_txn" target="_blank" class="underline">{{confirmed_txn}}</a>
        </div>
        <button @click="submit(hero_settings.finalAction)" class="max-w-sm py-2 px-14 bg-neon_green m-auto text-black font-semibold">{{hero_settings.button}}</button>
        <!-- <span v-if="this.txn_exists" class="text-sm text-white-500 hover:underline cursor-pointer" @click="submit(1)"></span> -->
        <span class="text-lg text-red-500">{{hero_settings.error}}</span>
      </div>
    </div>
</template>

<script>
import hashes from '@/assets/json/hashes.json'

export default {
  name: 'order_confirmed',
  props: {
    selected_token: String,
    confirmed_txn: String,
  },
  created(){
    this.hero_settings.button = "GO BACK"
    this.hero_settings.finalAction = 1
  },
  mounted() {
    if(this.confirmed_txn) this.txn_exists=true 
    
    if(!this.txn_exists){
      this.hero_settings.main = "ORDER CANCELED",
      this.hero_settings.sub = "YOUR ORDER DID NOT GO THROUGH, TRY AGAIN"
      this.hero_settings.button = "GO BACK"
      this.hero_settings.finalAction = 1
    }
  },
  data() {
    return{
      txn_exists: false,
      hero_settings:{
        main: "ORDER CONFIRMED",
        sub: "PLEASE ALLOW 90-120 DAYS FOR ORDER TO BE SHIPPED",
        button: "",
        finalAction: 1,
        error: null
      },
      confirmed: null,
      error: null
    }
  },
  methods:{
    submit(option){
      console.log(option)
      this.$emit('final_action',option)
    },
    
  }
}
</script>