<template>
  <div id="app" :class="['bg-black min-h-screen h-full text-white',page_num===0 || page_num===4 ? 'flex justify-center items-center' : '']">

    <!-- Reset App button -->
    <!-- <div v-if="page_num!==0 && page_num!==4" class="absolute top-0 left-0 mx-5 my-2">
      <div class="flex align-middle gap-x-3 cursor-pointer" @click="_reset_form()">
        <img src="@/assets/images/reload.svg">
        <button>Start Over</button>
      </div>
    </div> -->

    <!-- First page for connecting wallet -->
    <div v-if="page_num===0">
      <lander @connect_wallet="_connect_wallet()" :message="hero_settings.error" />
      <!-- <div class="flex flex-col justify-center text-center space-y-5">
        <h1 class="text-7xl font-semibold">{{hero_settings.main}}</h1>
        <span class="text-lg">{{hero_settings.sub}}</span>
        <span>{{499 - mint_count}} / 500 Them Hats Minted</span>
        <span class="text-lg pt-5">ORDERS CLOSE 9/30/2021 at 11:59pm PST</span>
        <button @click="page_num==4 ? _reset_form() : _connect_wallet()" class="max-w-sm py-2 px-14 bg-neon_green m-auto text-black font-semibold">{{hero_settings.button}}</button>
        <span class="text-lg text-red-500">{{hero_settings.error}}</span>
      </div> -->
    </div>

    <!-- Select your rugs page -->
    <div v-else-if="page_num===1" class="flex justify-center">
      <select-rugs :token_ids="owned_rugs" @rug_selected="rug_selected"></select-rugs>
    </div>

    <!-- Enter Shipping info page -->
    <div v-else-if="page_num===2" class="flex justify-center">
      <shipping-info @shipping_info_entered="shipping_info_entered"></shipping-info>
    </div>

    <!-- Confirmation page -->
    <div v-else-if="page_num===3" class="flex justify-center">
      <confirmation :selected_token="selected_token" :shipping_info="shipping_info" @order_confirmed="order_confirmed"></confirmation>
    </div>

    <!-- Order Confirmed page -->
    <div v-else-if="page_num===4" class="flex justify-center">
      <order-confirmed 
        :selected_token="selected_token"
        :confirmed_txn="confirmed_txn"
        @final_action="final_action"
        ></order-confirmed>
    </div>
  </div>
</template>

<script>
import lander from "./components/lander.vue"
import select_rugs from "./components/select_rugs.vue"
import shipping_info from "./components/shipping_info.vue"
import confirmation from "./components/confirmation.vue"
import web3_helpers from "./assets/js/web3_helpers.js"
import order_confirmed from './components/order_confirmed.vue'

export default {
  name: 'App',
  data(){
    return{
      page_num:0,
      hero_settings:{
        main: "THEM MOTHER FUCKING RUG HATS.",
        sub: "YOU MUST OWN HATS/HATTERS AND RUG PUNK(S) TO PROCEED.",
        button: "ORDER",
        error: null
      },
      mint_count: null,
      connected_address:null,
      owned_rugs: null,
      selected_token: null,
      shipping_info: null,
      confirmed_txn: null,
    }
  },
  async mounted(){   
    this.mint_count = await web3_helpers.get_mint_count()
  },
  methods:{
    async _connect_wallet(){
      const connect_result = await web3_helpers.connect_to_wallet()
      if(connect_result.status=="error"){
        this.hero_settings.error=connect_result.body
      } else {
        this.connected_address = connect_result.body

        // Check for rugs
        this.owned_rugs = await web3_helpers.get_owned_rugs(this.connected_address)
        if (this.owned_rugs.length == 0){
          this.hero_settings.error = "NO RUGS!"
          return
        }
        // Check for hats or hatters
        const num_hats = await web3_helpers.owns_hat(this.connected_address)
        const num_hatters = await web3_helpers.owns_hatter(this.connected_address)
        
        if(num_hats<1 && num_hatters<1){
          this.hero_settings.error = "NO HATS OR HATTERS"
          return
        }

        // Check that they have enough eth
        const price = 0.015
        const wallet_balance = await web3_helpers.get_wallet_balance()
        if(price>wallet_balance){
          this.hero_settings.error = "NOT ENOUGH ETH"
          return
        }

        this.page_num = 1
      }
    },
    // Reset form to first stage
    _reset_form(){
      this.connected_address = null
      this.owned_rugs = null
      this.selected_token = null
      this.shipping_info = null
      this.page_num = 0
    },
    // emitted after rug is selected
    rug_selected(selected_token){
      this.selected_token = selected_token
      this.page_num = 2
    },
    // emitted after shipping info is submitted
    shipping_info_entered(shipping_info){
      this.shipping_info = shipping_info
      this.page_num = 3
    },
    // emitted after order is confirmed. 
    // loops through selected rugs and creates burn requests
    async order_confirmed(){
      const shipping_info = this.shipping_info
      const sub_obj={
        selected_rug: this.selected_token,
        ...shipping_info
      }
      const confirmed_txn = (await web3_helpers.confirm_order(sub_obj,this.selected_token))
      this.confirmed_txn = confirmed_txn
      console.log(this.confirmed_txn)
      this.page_num = 4
    },
    final_action(option){
      // What button did they press on the last page
      switch (option){
        case 1:
          this._reset_form()
          break;
        case 2:
          this.selected_token = null
          this.shipping_info = null
          this.page_num = 1
          break;
        case 3:
          this._reset_form()
          window.open('https://passes.rug.wtf/')
          break;
        case 4:
          this._reset_form()
          window.open('https://opensea.io/collection/rpop/')
          break;
      }
    }
  },
  components: {
    "lander": lander,
    "select-rugs": select_rugs,
    "shipping-info":shipping_info,
    "confirmation":confirmation,
    "order-confirmed":order_confirmed
  },
}
</script>
