<template>
  <div class="flex flex-col justify-center gap-y-5 max-w-6xl">
    <h1 class="text-white text-6xl font-semibold self-center">SELECT YOUR RUG</h1>
    <div class="flex flex-wrap justify-center align-center max-w-screen-2xl gap-4">
      <div v-for="token in token_ids" :key="token" @click="toggle_selected_rug(token)" class="cursor-pointer w-52" 
      :style="selected_token == token ? 'outline: 5px solid #00ff89' : ''">
        <img :src="aws_url+token+'.png'">
      </div>
    </div>
    <button class="max-w-sm py-2 px-16 bg-neon_green m-auto text-black font-semibold" @click="submit()">NEXT</button>
    <!-- <span class="text-white self-center"></span> -->
    <span class="text-lg text-red-500 self-center">{{error}}</span>
  </div>
</template>

<script>
import hashes from '@/assets/json/hashes.json'

export default {
  name: 'select-rugs',
  props: {
    token_ids: Array
  },
  data(){
    return{
      aws_url: hashes.aws_rug_images,
      selected_token: null,
      error: null
    }
  },
  methods:{
    toggle_selected_rug(token){
      this.selected_token = token
      this.$forceUpdate()
    },
    submit(){
      if(!this.selected_token){
        this.error = "You must select a rug before continuing"
      }else{
        this.$emit('rug_selected',this.selected_token)
      }
    }
  }
}
</script>