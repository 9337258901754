const axios = require('axios')

import hashes from "@/assets/json/hashes.json"
import rug_punk from "@/assets/json/rugpunk.json"
import hat from "@/assets/json/hat.json"
import hatter from "@/assets/json/hatter.json"
import physical_hat from "@/assets/json/physical_hat.json"

let address
let balance
let chainid;

const contracts={
    rug_punk:rug_punk,
    hat: hat,
    hatter: hatter,
    physical_hat:physical_hat
}
const Web3 = require('web3')
const web3 = new Web3(Web3.givenProvider);
const rug_punk_contract = new web3.eth.Contract(contracts.rug_punk.abi, contracts.rug_punk.address)
const hat_contract = new web3.eth.Contract(contracts.hat.abi, contracts.hat.address)
const hatter_contract = new web3.eth.Contract(contracts.hatter.abi, contracts.hatter.address)
const physical_hat_contract = new web3.eth.Contract(contracts.physical_hat.abi, contracts.physical_hat.address)

let connect_to_wallet=async()=>{    
    chainid = await web3.eth.getChainId()
    const { ethereum } = window;

    if (process.env.NODE_ENV === 'production') {
        if (chainid !== 1){
            return ({
                status: "error",
                body: 'Please switch to Mainnet!'
            })
        }
    }

    if (ethereum && ethereum.isMetaMask) {
        // console.log('Ethereum successfully detected!');
        // Access the decentralized web!
        await get_wallet_balance();
        //let thing = Number(await order_pass_contract.methods.totalMinted().call()) + 125

        return ({
            status: "success",
            body: address
        })
    } else {
      return ({
          status: "error",
          body: 'Please install MetaMask!'
      })
    }

  }

const confirm_order=async(form_data, token)=>{
    // Sign request to verify on server
    const sign_response = await sign_personal_msg()
    console.log(sign_response)

    // Exit if signature failed
    if (!sign_response) {
        console.log("An error occurred while signing the request.")
        return false
    }

    const sent_obj = {
        address: address,
        token_id: token,
        signature: sign_response,
        txn_hash: null,
        ...form_data
    }

    // Send to server
    await axios.post(hashes.aws_order_sub,sent_obj)

    // Mint a token, add result to the sent_obj
    sent_obj.txn_hash = await physical_hat_contract.methods.claim(1).send(
        {
            from:address,
            value: web3.utils.toWei(String((15 * 1)/1000))
        }
    ).then(mint_txn=>{
        const mint_hash = mint_txn.transactionHash
        return mint_hash
    }).catch(err=>{
        return null
    })

    // Send to server with hash
    const response = await axios.post(hashes.aws_order_sub,sent_obj)
    console.log(response)

    return sent_obj.txn_hash
}

const sign_personal_msg = async()=>{
    const sign_response = await web3.eth.personal.sign("Them Hat Order",address).catch(e=>null)
    return sign_response
}

const get_wallet_balance = async()=>{

    address = await lookup_address();
    console.log(address)

    const wallet_ballance = web3.eth.getBalance(address, function(err, ret) {
        console.log((ret/Math.pow(10,18)));
        balance = (ret/Math.pow(10,18)).toFixed(4);
        return balance
    }); 

    return wallet_ballance
}

const lookup_address = async()=>{
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
    const account = accounts[0];
    return account;
}

const get_owned_rugs = async(address)=>{
    // Use manual method for finding all the owned tokens
    return _manually_find_owned_tokens(rug_punk_contract,address)
}

const owns_hat = async(address)=>{
    // Can use simple method since it's built into the smart contract
    const num_owned_hats = await hat_contract.methods.balanceOf(address).call()
    return num_owned_hats
}

const owns_hatter = async(address)=>{
    // Can use simple method since it's built into the smart contract
    const num_owned_hatters = await hatter_contract.methods.balanceOf(address).call()
    return num_owned_hatters
}

const get_mint_count = async()=>{
    const totalSupply = await physical_hat_contract.methods.tokenRemaining().call()
    return totalSupply
}

export default {connect_to_wallet,get_wallet_balance,lookup_address,get_owned_rugs,confirm_order,owns_hat,owns_hatter,get_mint_count}

//! Other functions for internal use
async function _manually_find_owned_tokens(contract,address){
    const contract_start_block = 12783002 // Block the contract was minted on

    // Find all the Rugs EVER sent to the address
    const transfer_events = await contract.getPastEvents('Transfer', {filter:{to:address}, fromBlock: contract_start_block})
    const tokens_sent_to_address = transfer_events.map(event=>event.returnValues.tokenId)
    const unique_tokens_sent_to_address = [...new Set(tokens_sent_to_address)];

    // Find the current owner of those rugs
    const token_owners_promises = unique_tokens_sent_to_address.map(token=>{
        let temp = {}
        const owner = contract.methods.ownerOf(token).call().catch(e=>null)
        temp[token] = owner
        return temp
    })

    // Filter out empty obj without an owner
    const token_owners = await _promise_all_for_object(token_owners_promises)//voodoo
    const clean_token_owners = token_owners.filter(obj=>Object.values(obj)[0])

    // Get the final list of rugs that belong to the address
    const tokens_owned_by_address = clean_token_owners.map(obj=>{
        if(Object.values(obj)[0] && Object.values(obj)[0].toLowerCase() == address.toLowerCase()) return Object.keys(obj)[0]
    }).filter(e=>e)

    return tokens_owned_by_address
}
function _promise_all_for_object(array) {
    // Pass in an array of objects with the values being promises, will return awaited promise
    const promisedProperties = array.map(obj=>Object.values(obj)[0])
    const objectKeys = array.map(obj=>Object.keys(obj))
    
    return Promise.all(promisedProperties)
      .then((resolvedValues) => {
        return resolvedValues.reduce((resolvedArr, property, index) => {
            resolvedArr[index][objectKeys[index]] = property;
            return resolvedArr;
        }, array);
    });
}