<template>
  <div class="flex flex-col justify-center gap-y-14 max-w-6xl">
    <h1 class="text-white text-6xl font-semibold self-center">YOUR ORDER</h1>
    <!-- Selected Token Cards -->
    <div class="flex flex-wrap justify-center align-center max-w-screen-2xl gap-4">
        <img :src="aws_url+selected_token+'.png'" class="w-52" style="outline: 5px solid #00ff89">
    </div>
    <!-- Shipping info -->
    <div class="flex flex-col justify-center items-center uppercase">
      <span>{{shipping_info.name}}</span>
      <span>{{shipping_info.address1}}</span>
      <span>{{shipping_info.address2}}</span>
      <span>{{shipping_info.country}}</span>
      <span>{{shipping_info.city}}, {{shipping_info.state}} {{shipping_info.zip}}</span>
    </div>
    <!-- Confirmation button -->
    <div v-if="!confirmed" class="flex flex-col justify-center gap-y-4">
      <button class="max-w-sm py-2 px-16 bg-neon_green m-auto text-black font-semibold" @click="submit()">ARE YOU SURE?</button>
      <span class="text-yellow-400 self-center">THIS IS FINAL. THERE WILL BE NO WAY TO CHANGE SHIPPING INFO AFTER SUBMITTING.</span>
    </div>
    <div v-if="processing" class="flex flex-col justify-center gap-y-5">
      <span class="text-yellow-400 self-center">DO NOT CLOSE THIS WINDOW UNTIL YOUR ORDER IS CONFIRMED.</span>
      <div class="flex justify-center gap-x-5">
        <span class="text-white self-center">PROCESSING</span>
        <div class="loader"></div>
      </div>
    </div>
    <span class="text-lg text-red-500 self-center">{{error}}</span>
  </div>
</template>

<script>
import hashes from '@/assets/json/hashes.json'

export default {
  name: 'confirmation',
  props: {
    selected_token: String,
    shipping_info: Object
  },
  data(){
    return{
      aws_url: hashes.aws_rug_images,
      processing: false,
      confirmed: null,
      error: null
    }
  },
  methods:{
    submit(){
      this.confirmed = true
      this.processing = true
      this.$emit('order_confirmed')
    }
  }
}
</script>

<style>
.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #00ff89; /* Blue */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>