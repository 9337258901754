<template>
  <div class="w-screen md:h-screen mx-auto my-auto flex flex-col gap-y-6 md:gap-y-0 px-1 md:px-4 items-center justify-center font-sans overflow-x-hidden md:overflow-hidden">
    <div id="title" class="flex-grow w-full flex flex-row items-start justify-start md:pt-4">
      <div class="font-black text-6xl md:text-9xl">
        <div id="rug">RUG X</div>
        <img id="them" src="@/assets/images/them-logo.png" alt="" class="h-14 md:h-44 object-cover md:transform md:-translate-y-6" />
      </div>
    </div>
    <div class="w-full flex flex-col md:flex-row items-start justify-between gap-x-6 md:pb-4">
      <div class="h-full flex flex-col items-start justify-end font-black text-6xl md:text-9xl">
        <div>
          <div>MOTHER</div>
          <div>FUCKING</div>
          <div>RUG</div>
          <div>HATS<span style="color: #75fb95">.</span></div>
        </div>
      </div>
      <div class="flex-grow h-full flex flex-col items-center md:items-end justify-end gap-y-6 max-w-lg">
        <div class="h-1/2 flex flex-row items-center justify-center gap-x-2 pt-4">
          <CoolLightBox :items="get_images(images)" :index="lightbox_index" @close="lightbox_index = null"> </CoolLightBox>
          <div v-for="(image, imageIndex) in images" :key="image" @click="lightbox_index = imageIndex" class="border border-white">
            <img :src="get_image(image)" alt="" class="md:h-full object-contain cursor-pointer" />
          </div>
        </div>
        <div class="font-bold text-xl md:text-4xl">0.015Ξ</div>
        <button @click="order_pressed" class="bg-neon_green font-bold text-black text-xl md:text-6xl px-6 py-1">ORDER</button>
        <div v-if="message" class="font-medium text-red-500 text-md md:text-xl text-center md:text-right">{{message}}</div>
        <div class="font-bold text-md md:text-xl uppercase text-center md:text-right lg:whitespace-nowrap">
          You must own
          <a href="https://opensea.io/collection/them-hats" target="_blank" class="underline">hats</a>/<a href="https://opensea.io/collection/them-hatters" target="_blank" class="underline">hatters</a>
          and
          <a href="https://opensea.io/collection/rug-wtf" target="_blank" class="underline">rug punk</a>(s) to proceed.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: 'lander',
  components: {
    CoolLightBox,
  },
  props: {
    message: String,
  },
  data() {
    return {
      images: ['hat-frontside.png', 'hat-side.png', 'hat-design.png'],
      lightbox_index: null,
    }
  },
  mounted() {
    this._dynamic_title_height()
    window.addEventListener('resize', this._dynamic_title_height);
    // window.onresize = this._dynamic_title_height()
  },
  methods: {
    order_pressed() {
      this.$emit('connect_wallet')
    },
    get_image(image_name) {
      return require('@/assets/images/' + image_name)
    },
    get_images(image_names) {
      return image_names.map((image_name) => {
        return require('@/assets/images/' + image_name)
      })
    },
    _dynamic_title_height() {
      let height = window.innerHeight - 550
      let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth 
      let rug = document.getElementById("rug")
      let them = document.getElementById("them")
      if (width < 768 || height < 160) {
        rug.style.fontSize = null
        them.style.height = null
        return
      }
      // console.log("resized")
      // console.log(String(height / 2))
      rug.style.fontSize = String(height / 2) + 'px'
      them.style.height = String(height / 2) + 'px'
    },
  },
}
</script>

<style scoped>
/* .dynamic-title {
  transform: scale(calc(200vh / (100vh - 528px)));
} */
</style>